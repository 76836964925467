export function getSources() {
    return [

        {
          "value": "Startup Wise Guys Facebook page",
          "label": "Startup Wise Guys Facebook page"
        },
        {
          "value": "Startup Wise Guys Instagram",
          "label": "Startup Wise Guys Instagram"
        },
        {
          "value": "EBS Facebook page",
          "label": "EBS Facebook page"
        },
        {
          "value": "EBS Instagram page",
          "label": "EBS Instagram page"
        },
        {
          "value": "Friends / Word of mouth",
          "label": "Friends / Word of mouth"
        },
        {
          "value": "Other",
          "label": "Other"
        }


      ]
}
