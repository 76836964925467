export function getEducations() {
    return [

        {
          "value": "Highschool",
          "label": "Highschool"
        },
        {
          "value": "University: Bachelor's degree YEAR 1",
          "label": "University: Bachelor's degree YEAR 1"
        },
        {
          "value": "University: Bachelor's degree YEAR 2",
          "label": "University: Bachelor's degree YEAR 2"
        },
        {
          "value": "University: Bachelor's degree YEAR 3",
          "label": "University: Bachelor's degree YEAR 3"
        },
        {
          "value": "University: Bachelor's degree YEAR 4",
          "label": "University: Bachelor's degree YEAR 4"
        },
        {
          "value": "University: Master's degree YEAR 1",
          "label": "University: Master's degree YEAR 1"
        },
        {
          "value": "University: Master's degree YEAR 2",
          "label": "University: Master's degree YEAR 2"
        },
        {
          "value": "University: Master's degree YEAR 3",
          "label": "University: Master's degree YEAR 3"
        },
        {
          "value": "PHD / Doctoral studies",
          "label": "PHD / Doctoral studies"
        },
        {
          "value": "Graduated",
          "label": "Graduated"
        },
        {
          "value": "Other",
          "label": "Other"
        }


      ]
}
