import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import logo from '../../assets/ye-summerschool.png';

import postData from "../../axiosUtils";
//import { putData } from "../../axiosUtils";
//import axios from 'axios';

import { withStyles, makeStyles } from '@material-ui/core/styles';
//import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import { Typography } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#D0D0D0',
        color: '#000000',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '40px',
        marginLeft: '16%',
        marginRight: '16%',
        [theme.breakpoints.down('md')]: {
            paddingTop: '40px',
            marginLeft: '2%',
            marginRight: '2%',
          },
    },
    root1: {
        flexGrow: 1,
    },
    logo: {
        width: '100%',
        borderRadius: '10px',
    },
    swgpaper: {
        marginTop: '10px',
        marginBottom: '10px',
        padding: '20px',
    },
    companysection: {
        backgroundColor: '#F0F0F0',
    },
    boxtitle: {
        marginBottom: '20px',
    },
    border: {
        border: 'solid 1px lightgrey',
        padding: '10px',
    },
    boxsection: {
        maxWidth: '80%',
        width: '80%',
    },

    totals: {
        fontWeight: 900,
    },
    rowcolor: {
        backgroundColor: '#F0F0F0',
        color: '#000000',
    },
    distance: {
        marginTop: '50px',
    }



}));



function Overview(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [data, setData] = useState(location.state.data);
    const [returnData, setReturndata] = useState();

    useEffect(() => {

        getTicketprice(data.count, data.participant).then(
            res => setReturndata(res)
        )


    }, [data.count, data.participant]);



    //console.log(returnData && returnData);

    //console.log(returnData && returnData.participant);


    async function getTicketprice(count, participant) {
        const path = 'ticketcalc-ye';
        const body = {
            count: count,
            participant: participant,
        }

        return await postData(path, body);
    }

    async function loadCheckout() {
        //alert('buy now pressed');
        history.push({
            pathname: '/checkout',
            state: { returnData } // your data array of objects
        });

    }

    return (
        <div className={classes.root}>
            <img src={logo} alt="Logo" className={classes.logo} />
            <Paper elevation={2} className={classes.swgpaperborder}>
                <Box className={classes.boxsection} p={1} m="auto">

                    <Typography variant='h4' className={classes.boxtitle}>Summary</Typography>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell><Typography>Firstname</Typography></StyledTableCell>
                                    <StyledTableCell><Typography>Lastname</Typography></StyledTableCell>
                                    <StyledTableCell><Typography>Email</Typography></StyledTableCell>
                                    <StyledTableCell><Typography>Phone</Typography></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {returnData &&

                                    <TableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography>{returnData.participant.firstname}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Typography>{returnData.participant.lastname}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Typography>{returnData.participant.email}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Typography>{returnData.participant.phone}</Typography>
                                        </StyledTableCell>

                                    </TableRow>

                                }

                                <TableRow className={classes.rowcolor}>
                                    <TableCell align='right' colSpan={3}>
                                        <Typography className={classes.totals}>Total Price</Typography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Typography className={classes.totals}>{returnData && returnData.totalprice} €</Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell align='right' colSpan={3}>
                                        <Typography >VAT 20%</Typography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Typography >{returnData && returnData.vatamount} €</Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow className={classes.rowcolor}>
                                    <TableCell align='right' colSpan={3}>
                                        <Typography className={classes.totals} >Grand Total</Typography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Typography className={classes.totals}>{returnData && returnData.grandtotal} €</Typography>
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.distance}
                        fullWidth
                        onClick={loadCheckout}
                    >
                        Pay and register now
                </Button>

                </Box>
            </Paper>

        </div>
    );

}

export default Overview;
