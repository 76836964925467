import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import Typography from '@material-ui/core/Typography';

import logo from '../assets/ye-summerschool.png';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#cecece',
        flexGrow: 1,
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        fontSize: '2em',
    },
    logo: {
        width: '70%',
        marginRight: '15%',
        marginLeft: '15%',
    }


}));

export default function Nav() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar>
                    <img src={logo} alt="Logo" className={classes.logo} />
                    {/* <Typography variant="h6" className={classes.title}>
                        GetAway Tickets
                    </Typography> */}
                </Toolbar>
            </AppBar>
        </div>
    );

}