export function getSizes() {
    return [

        {
          "value": "XS",
          "label": "XS"
        },
        {
          "value": "S",
          "label": "S"
        },
        {
          "value": "M",
          "label": "M"
        },
        {
          "value": "L",
          "label": "L"
        },
        {
          "value": "XL",
          "label": "XL"
        },
        {
          "value": "XXL",
          "label": "XXL"
        }


      ]
}
