export function getFoodrestrictions() {
    return [
        {
            "value": "None",
            "label": "None"
        },
        {
            "value": "Vegan",
            "label": "Vegan"
        },
        {
            "value": "Vegetarian",
            "label": "Vegetarian"
        },
        {
            "value": "Other",
            "label": "Other"
        }

    ]
}
