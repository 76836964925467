import React from "react";
import { Route, Switch } from "react-router-dom";
import Home1 from "./containers/Home/Home1";
import Overview from "./containers/Overview/Overview";
import Checkout from "./containers/Checkout/Checkout";
import Success from "./containers/End/Success";
import Fail from "./containers/End/Fail";

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <Home1 />
            </Route>
            {/* <Route exact path="/detail/:id">
                <Detailview />
            </Route> */}
            <Route exact path="/overview">
                <Overview />
            </Route>

            <Route exact path="/checkout">
                <Checkout />
            </Route>

            <Route exact path="/success">
                <Success />
            </Route>

            <Route exact path="/fail">
                <Fail />
            </Route>

            {/*
            <Route exact path="/project/detail/:projectid">
                <ProjectDetail />
            </Route>
            <Route exact path="/project/update/:projectid/:cusid/:pname">
                <UpdateProject />
            </Route> */}



        </Switch>
    );
}