import React from 'react';

//import { Auth } from "aws-amplify";
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';

import Nav from './components/Nav';
import Footer from './components/Footer';

import Routes from "./Routes";


//import { getData } from "./utils";


function App() {

    return (


        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {/* <Nav /> */}
                <Routes />
                {/* <Footer /> */}
            </ThemeProvider>
        </>
    );
}

//export default withAuthenticator(App);
export default App;
