import axios from 'axios';
//import { Auth } from 'aws-amplify';
const baseUrl = 'https://wxhyswyps6.execute-api.eu-west-1.amazonaws.com/dev/';

async function getData(path, body) {


    const config = {
        baseURL: baseUrl,
        url: path,
        method: 'get',
        //data: body,
        headers: {
            /* Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}` */
        }
    }

    return await axios(config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.log("Error with GET " + path + ": " + error);
    })
}

async function postData(path, body) {


    const config = {
        baseURL: baseUrl,
        url: path,
        method: 'post',
        data: body,
        headers: {
            /* Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}` */
        }
    }

    return await axios(config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.log("Error with POST " + path + ": " + error);
    })
}

async function putData(path, body) {


    const config = {
        baseURL: baseUrl,
        url: path,
        method: 'put',
        data: body,
        headers: {
            /* Authorization: `${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}` */
        }
    }

    return await axios(config)
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.log("Error with PUT " + path + ": " + error);
    })
}

export {getData, putData, postData}
//export default getData
export default postData