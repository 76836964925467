import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { ColorConstants } from "./constants/const";
//import AvenirNextBold from "./resources/fonts/AvenirNext-Bold.woff2";
//import AvenirNextRegular from "./resources/fonts/AvenirNext-Regular.woff2";
//import AvenirNextDemiBold from "./resources/fonts/AvenirNext-DemiBold.woff2";
//import AvenirNextMedium from "./resources/fonts/AvenirNext-Medium.woff2";
//import AvenirNextMediumItalic from "./resources/fonts/AvenirNext-MediumItalic.woff2";


const colorConstants = {
  PRIMARY_RED: "#dc3545",
  SECONDARY_BLACK: "#000000",
  PRIMARY_ORANGE: "#f4752a",
  SECONDARY_YELLOW: "#FFD426",
  DARKEST_GRAY: "#3E4246",
  DARKER_GRAY: "#52575C",
  DARK_GRAY: "#C0C2C6",
  LIGHTEST_GRAY: "#F9F9FA",
  LIGHTER_GRAY: "#F1F1F1",
  LIGHT_GRAY: "#E8E8E8",
  GREEN: "#2AC769",
  RED: "#FB4E4E",
};

// const avenirRegular = {
//   fontFamily: "AvenirNextRegular",
//   fontStyle: "normal",
//   src: `
//       local('AvenirNextRegular'),
//       local('AvenirNextRegular'),
//       url(${AvenirNextRegular}) format('woff2')
//     `,
// };


// const avenirBold = {
//   fontFamily: "AvenirNextBold",
//   fontStyle: "normal",
//   src: `
//         local('AvenirNextBold'),
//         local('AvenirNextBold'),
//         url(${AvenirNextBold}) format('woff2')
//       `,
// };

// const avenirDemiBold = {
//   fontFamily: "AvenirNextDemiBold",
//   fontStyle: "normal",
//   src: `
//         local('AvenirNextDemiBold'),
//         local('AvenirNextDemiBold'),
//         url(${AvenirNextDemiBold}) format('woff2')
//       `,
// };

// const avenirMedium = {
//   fontFamily: "AvenirNextMedium",
//   fontStyle: "normal",
//   src: `
//           local('AvenirNextMedium'),
//           local('AvenirNextMedium'),
//           url(${AvenirNextMedium}) format('woff2')
//         `,
// };

// const avenirMediumItalic = {
//   fontFamily: "AvenirNextMediumItalic",
//   fontStyle: "normal",
//   src: `
//             local('AvenirNextMediumItalic'),
//             local('AvenirNexttMediumItalic'),
//             url(${AvenirNextMediumItalic}) format('woff2')
//           `,
// };

// @ts-ignore
export default responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        main: colorConstants.PRIMARY_RED,
      },
      secondary: {
        main: colorConstants.SECONDARY_BLACK,
      },
      background: {
        default: colorConstants.LIGHTER_GRAY,
        paper: "#fff",
      },
      text: {
        primary: colorConstants.DARKEST_GRAY,
        secondary: colorConstants.DARKEST_GRAY,
      },

  },
    typography: {
    fontFamily: [
      "Montserrat",
      //"AvenirNexttRegular",
      //"AvenirNextBold",
      //"AvenirNextDemiBold",
      //"AvenirNextMedium",
      //"AvenirNextMediumItalic",
      //"AvenirNextRegular",
      //"-apple-system",
      //"BlinkMacSystemFont",
      //'"Segoe UI"',
      //"Roboto",
      //'"Helvetica Neue"',
      //"Arial",
      //"sans-serif",
      //'"Apple Color Emoji"',
      //'"Segoe UI Emoji"',
      //'"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: 52,
      //fontFamily: "AvenirNextBold",
      fontFamily: "Montserrat",
      fontWeight: 600,
      letterSpacing: "0.2px",
    },
    h2: {
      fontSize: 44,
      //fontFamily: "AvenirNextBold",
      fontFamily: "Montserrat",
      fontWeight: 600,
      letterSpacing: "0px",
    },
    h3: {
      fontSize: 32,
      //fontFamily: "AvenirNextBold",
      fontFamily: "Montserrat",
      fontWeight: 600,
      letterSpacing: "0.1px",
    },
    h4: {
      fontSize: 26,
      //fontFamily: "AvenirNextBold",
      fontFamily: "Montserrat",
      fontWeight: 500,
      letterSpacing: "0.2px",
    },
    h5: {
      fontSize: 20,
      //fontFamily: "AvenirNextBold",
      fontFamily: "Montserrat",
      fontWeight: 500,
      letterSpacing: "0.2px",
    },
    h6: {
      fontSize: 18,
      //fontFamily: "AvenirNextBold",
      fontFamily: "Montserrat",
      fontWeight: 500,
      letterSpacing: "0.2px",
    },
    subtitle1: {
      fontSize: 16,
      //fontFamily: "AvenirNextBold",
      fontFamily: "Montserrat",
      fontWeight: 500,
      letterSpacing: "0.1px",
      "@media (max-width:767px)": {
        fontSize: 14,
      },
    },
    subtitle2: {
      fontSize: 14,
      letterSpacing: "0.1px",
      //fontFamily: "AvenirNextDemiBold",
      fontFamily: "Montserrat",
      fontWeight: 500,
      color: colorConstants.DARK_GRAY,
    },
    body1: {
      fontSize: 18,
      letterSpacing: "0.2px",
      //fontFamily: "AvenirNextRegular",
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      letterSpacing: "0.2px",
      //fontFamily: "AvenirNextRegular",
      fontFamily: "Montserrat",
      fontWeight: 400,
    },

  },
    overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          //avenirRegular,
          //avenirMedium,
          //avenirMediumItalic,
          //avenirDemiBold,
          //avenirBold,
          'Montserrat',
        ],
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "38px",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
        fontSize: 16,
        letterSpacing: "0.2px",
        fontFamily: "Montserrat",
      },
      containedPrimary: {
        color: "#FFFFFF",
        backgroundColor: colorConstants.PRIMARY_BLUE,
        "'& .MuiButton-label": {
          color: colorConstants.PRIMARY_BLUE,
        },
      },
      containedSecondary: {
        backgroundColor: colorConstants.PRIMARY_RED,
        color: "#FFFFFF",
      },
      outlinedPrimary: {
        color: colorConstants.PRIMARY_BLUE,
      },
      outlinedSecondary: {
        color: colorConstants.PRIMARY_ORANGE,
      },
    },


  },
  })
);
