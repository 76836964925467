import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import logo from '../../assets/ye-summerschool.png';

//import postData from "../../axiosUtils";
//import { putData } from "../../axiosUtils";
//import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
//import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Box from "@material-ui/core/Box";
//import Button from "@material-ui/core/Button";
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '40px',
        marginLeft: '16%',
        marginRight: '16%',
    },
    root1: {
        flexGrow: 1,
    },
    swgpaper: {
        marginTop: '10px',
        marginBottom: '10px',
        padding: '20px',
    },
    logo: {
        width: '100%',
        borderRadius: '10px',
    },

    companysection: {
        backgroundColor: '#F0F0F0',
    },
    boxtitle: {
        marginBottom: '20px',
    },
    border: {
        border: 'solid 1px lightgrey',
        padding: '10px',
    },
    boxsection: {
        maxWidth: '80%',
        width: '80%',
    },

    totals: {
        fontWeight: 900,
    },
    rowcolor: {
        backgroundColor: '#F0F0F0',
        color: '#000000',
    },
    distance: {
        marginTop: '50px',
    }



}));




function Fail(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    //here comes email sending mechanism in useEffect

    const [data, setData] = useState(location.state.data);

    //console.log(data && data);

    return (
        <div className={classes.root}>
            <img src={logo} alt="Logo" className={classes.logo} />
            <Paper elevation={2} className={classes.swgpaperborder}>

                <Box className={classes.boxsection} p={2} m="auto">
                    <Typography variant='h4' className={classes.boxtitle}>There was an error "{data && data.error}" with your payment. Please start again.</Typography>


                </Box>
            </Paper>

        </div>
    );

}

export default Fail;