import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import {
    CardElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";


const useStyles = makeStyles((theme) => ({
    paybutton: {
        background: '#5469d4',
        fontFamily: 'Montserrat',
        color: '#ffffff',
        borderRadius: '0 0 4px 4px',
        border: 0,
        padding: '12px 16px',
        fontSize: '16px',
        fontWeight: 600,
        cursor: 'pointer',
        display: 'block',
        transition: 'all 0.2s ease',
        boxShadow: '0px 4px 5.5px 0px rgba(0, 0, 0, 0.07)',
        width: '100%',
    },


}));

export default function CheckoutForm() {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [data1, setData1] = useState(location.state.returnData);

    //console.log(data1 && data1);
    //console.log(location.state.returnData);

    const orderinfo = location.state.returnData;


    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    const [stripereturndata, setStripereturndata] = useState('');



    useEffect(() => {
        async function fetchData() {
            // You can await here
            const { data } = await axios.post("https://wxhyswyps6.execute-api.eu-west-1.amazonaws.com/dev/charge-ye", { orderinfo })
            //console.log(JSON.stringify(data));
            setClientSecret(data.clientsecret);
            setStripereturndata(data.orderinfo);

        }
        fetchData();
    }, [stripe]);

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);

            let data = {
                error: payload.error.message
            }

            history.push({
                pathname: '/fail',
                state: { data } // your data array of objects
            });

        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);

            //console.log(data1);

            //console.log(stripereturndata);

            // let data = {
            //     data: data1
            // }

             let data = {
                 data1: stripereturndata
             }

             history.push({
                 pathname: '/success',
                 state: { data } // your data array of objects
             });

        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
            <button
                className={classes.paybutton}
                disabled={processing || disabled || succeeded}
                id="submit"
            >
                <span id="button-text">
                  {processing ? (
                      <div className="spinner" id="spinner"></div>
                  ) : (
                      `Pay ${data1 && data1.grandtotal} €`
                  )}
                </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {/*{error && (*/}
            {/*    <div className="card-error" role="alert">*/}
            {/*        {error}*/}
            {/*    </div>*/}
            {/*)}*/}
            {/* Show a success message upon completion */}
            {/*<p className={succeeded ? "result-message" : "result-message hidden"}>*/}
            {/*    Payment succeeded, see the result in your*/}
            {/*    <a*/}
            {/*        href={`https://dashboard.stripe.com/test/payments`}*/}
            {/*    >*/}
            {/*        {" "}*/}
            {/*        Stripe dashboard.*/}
            {/*    </a> Refresh the page to pay again.*/}
            {/*</p>*/}
        </form>
    );
}
