export function getChoices() {
    return [

        {
          "value": "Yes",
          "label": "Yes"
        },
        {
          "value": "No",
          "label": "No"
        }
      ]
}
