import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import logo from '../../assets/ye-summerschool.png';


import postData from "../../axiosUtils";
//import { putData } from "../../axiosUtils";
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
//import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Typography } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '40px',
        marginLeft: '16%',
        marginRight: '16%',
    },
    root1: {
        flexGrow: 1,
    },
    swgpaper: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '40px',
        paddingRight: '20px',
        paddingBottom: '20px',
        paddingLeft: '20px',
    },
    logo: {
        width: '100%',
        borderRadius: '10px',
    },


    companysection: {
        backgroundColor: '#F0F0F0',
    },
    boxtitle: {
        marginBottom: '20px',
    },
    border: {
        border: 'solid 1px lightgrey',
        padding: '10px',
    },
    boxsection: {
        maxWidth: '80%',
        width: '80%',
    },

    totals: {
        fontWeight: 900,
    },
    rowcolor: {
        backgroundColor: '#F0F0F0',
        color: '#000000',
    },
    distance: {
        marginTop: '50px',
    },
    biggersize: {
        fontSize: '1.6em',
        marginBottom: '20px',
    },
    biggersize1: {
        fontSize: '1.6em',
    }



}));




function Success(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [data1, setData1] = useState(location.state.data);

    const [dbresult, setDbresult] = useState('');

    //console.log('state: ' + JSON.stringify(location.state.data));
    //console.log('from local state:' + JSON.stringify(data1 && data1.orderinfo));
    //console.log('from passed-in state:' + JSON.stringify(location.state.data.orderinfo.grandtotal));

    useEffect(() => {

        async function registerAll(data1) {
            const path = 'registering-ye';
            const body = {
                //data1: data1,
                data1: location.state.data,
            }

            return await postData(path, body);
        }

        registerAll(data1).then(
            res => setDbresult(res)
        )

    }, [data1]);


    //console.log(dbresult && JSON.stringify(dbresult));


    //here comes email sending mechanism in useEffect

    //const [data, setData] = useState(location.state.returnData);

    //console.log(data && data);

    return (
        <div className={classes.root}>
            <img src={logo} alt="Logo" className={classes.logo} />
            <Paper elevation={2} className={classes.swgpaperborder}>

                <Box className={classes.boxsection} p={2} m="auto">

                    <Typography variant='h3' className={classes.boxtitle}>Thank you for purchasing the ticket for Young Entrepreneurs! It will be mega! </Typography>

                    <Typography className={classes.biggersize}>We are super excited to have you part of this.</Typography>

                    <Typography className={classes.biggersize}>Remember this is not an ordinary event, it is the right environment where you can express your entrepreneurial creativity!</Typography>

                    <Typography className={classes.biggersize}>So stay tuned for updates and agenda at <a href="https://startupwiseguys.com/young-entrepreneurs/" target="_blank" rel="noopener noreferrer">https://startupwiseguys.com/young-entrepreneurs/</a>.</Typography>

                    <Typography className={classes.biggersize1}>Disruptive regards,</Typography>
                    <Typography className={classes.biggersize}>Startup Wise Guys team!</Typography>

                    <br />
                    <br />
                    <br />
                    <br />

                    <Typography className={classes.biggersize}><a href="https://startupwiseguys.com/privacy-statement/" target="_blank" rel="noopener noreferrer">Privacy policy</a></Typography>





                </Box>
            </Paper>

        </div>
    );

}

export default Success;