export function getEditions() {
    return [

        {
          "value": "EBS Tallinn 2021",
          "label": "EBS Tallinn 2021"
        },
        {
          "value": "EBS Helsinki 2021",
          "label": "EBS Helsinki 2021"
        },
        {
            "value": "EBS Tallinn 1+1 2021",
            "label": "Friends package 1+1: Tallinn edition (2 persons)"
        },
        {
            "value": "EBS Helsinki 1+1 2021",
            "label": "Friends package 1+1: Helsinki edition (2 persons)"
        }

      ]
}
