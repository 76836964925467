export default {
  /* s3: {
    REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
    BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
  }, */
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://wxhyswyps6.execute-api.eu-west-1.amazonaws.com/dev/"

  },
  /* cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_ShpH7Tykh",
    APP_CLIENT_ID: "1hnnp8vi46sm8o2lu5d2n1717l",
  } */
};