import React, { useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import logo from '../../assets/ye-summerschool.png';

import CheckoutForm1 from "./CheckoutForm";

import './Card.css';

import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Typography } from '@material-ui/core';
import Paper from "@material-ui/core/Paper";


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '40px',
        marginLeft: '16%',
        marginRight: '16%',
        [theme.breakpoints.down('md')]: {
            paddingTop: '40px',
            marginLeft: '2%',
            marginRight: '2%',
          },
    },
    root1: {
        flexGrow: 1,
    },
    swgpaper: {
        marginTop: '10px',
        marginBottom: '10px',
        padding: '20px',
    },
    logo: {
        width: '100%',
        borderRadius: '10px',
    },
    companysection: {
        backgroundColor: '#F0F0F0',
    },
    boxtitle: {
        marginBottom: '20px',
        textAlign: 'center',
    },
    border: {
        border: 'solid 1px lightgrey',
        padding: '10px',
    },
    boxsection: {
        maxWidth: '80%',
        width: '80%',
    },

    totals: {
        fontWeight: 900,
    },
    rowcolor: {
        backgroundColor: '#F0F0F0',
        color: '#000000',
    },
    distance: {
        marginTop: '50px',
    },
    distancebutton: {
        marginTop: '20px',
        marginLeft: '5px',
        marginRight: '5px',
        marginBottom: '5px',
        width: '50%',
        maxWidth: '90%',
    },
    stripeel: {
        margin: '5px',
        padding: '10px',
        border: 'solid 1px #cecece'
    },
    stripelabel: {
        //marginRight: '5px',
        //marginLeft: '5px',
        //marginBottom: '0px !important',
        padding: '10px',
    },
    alertmessage: {
        textAlign: 'center',
    }



}));




function Checkout(props) {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const [data1, setData1] = useState(location.state.returnData);

    //console.log(data1 && data1);
    //console.log(location.state.returnData);

    //const stripePromise = loadStripe("pk_test_PBSpiHrPwbTO9ukzwOwYK7Iu"); //oga test stripe
    //const stripePromise = loadStripe("pk_test_2RHJ2FFf1iwS4qRZYQAaHj1r00Gvi5nHr3"); //swg test stripe
    const stripePromise = loadStripe("pk_live_GYD6j0ukgTftONIucGMbKu0v006lys3Skx"); //swg live stripe
    //const stripePromise = loadStripe("pk_test_hRZVsxTmCXJFWuH5dGq0LxR600F2LNz7PA"); //oga moduulo test stripe new API version
    //const stripePromise = loadStripe("pk_live_Ney4y5H2PNLfKGq24ezauBi000rZbs8wtA"); //oga moduulo prod stripe new API version


    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();


        const handleSubmit = async (event) => {
            event.preventDefault();


            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement)

            });

            if (!error) {
                const { id } = paymentMethod;

                //console.log(JSON.stringify(id));

                const orderinfo = location.state.returnData;
                //console.log(orderinfo);

                try {

                    const { data } = await axios.post("https://wxhyswyps6.execute-api.eu-west-1.amazonaws.com/dev/charge-ye", { id, orderinfo })

                    //console.log(JSON.stringify(data));

                    if (data.stripepayment.status === 'succeeded') {

                        history.push({
                            pathname: '/success',
                            state: { data } // your data array of objects
                        });

                    } else {
                        history.push({
                            pathname: '/fail',
                            state: { data } // your data array of objects
                        });
                    }

                } catch (error) {
                    console.log(error);


                }
            }
        }


        return <form onSubmit={handleSubmit} style={{ maxWidth: "400px", margin: "2% auto", border: "solid 1px lightgrey" }}>

            <CardElement options={{
                style: {
                    base: {
                        fontSize: '16px',
                        color: '#424770',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#9e2146',
                    },
                },
            }} />

            <Button type="submit" disabled={!stripe} variant='contained' color='secondary' className={classes.distancebutton}>
                Pay {data1 && data1.grandtotal} €
            </Button>


        </form>
    }


    return (
        <div className={classes.root}>
            <img src={logo} alt="Logo" className={classes.logo} />
            <Paper elevation={2} className={classes.swgpaperborder}>
                <Box className={classes.boxsection} p={2} m="auto">
                    <Typography variant='h4' className={classes.boxtitle}>Checkout</Typography>

                    <Elements stripe={stripePromise}>
                        {/*<CheckoutForm data1={data1} />*/}
                        <CheckoutForm1 data1={data1} />
                    </Elements>

                    <Typography className={classes.alertmessage}>Please click only once and wait until page reloads</Typography>
                </Box>
            </Paper>

        </div>
    );

}

export default Checkout;