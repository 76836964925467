import React, {useState} from "react";
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import logo from '../../assets/ye-summerschool.png';

import postData from "../../axiosUtils";
//import axios from 'axios';

import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import {Typography} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {getEditions} from '../../constants/editions';
import {getEducations} from '../../constants/educations';
import {getSizes} from '../../constants/sizes';
import {getFoodrestrictions} from '../../constants/foodrestrictions';
import {getSources} from '../../constants/sources';
import {getCountry} from '../../constants/countries';
import {getChoices} from '../../constants/choices';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '40px',
        marginLeft: '16%',
        marginRight: '16%',
        [theme.breakpoints.down('md')]: {
            paddingTop: '40px',
            marginLeft: '2%',
            marginRight: '2%',
        },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    swgpaper: {
        marginTop: '10px',
        marginBottom: '10px',
        padding: '20px',
    },

    swgpaperborder: {
        marginTop: '10px',
        marginBottom: '10px',
        padding: '20px',
        borderTop: 'solid rgb(60, 81, 129) 10px',
    },
    logo: {
        width: '100%',
        borderRadius: '10px',
    },
    smalldivider: {
        marginTop: '10px',
    },
    largedivider: {
        marginTop: '40px',
    },
    footer: {
        backgroundColor: '#3E4246',
        color: 'white',
    },
    star: {
        color: '#dc3545',
    }


}));


function Home1() {
    const classes = useStyles();
    const history = useHistory();

    const editions = getEditions();
    const educations = getEducations();
    const sizes = getSizes();
    const foodrestrictions = getFoodrestrictions();
    const sources = getSources();
    const countries = getCountry();
    const choices = getChoices();

    /* const types = getTypes();
    const batches = getBatches();
    const dinnercities = getDinnercities();
    const tickets = getTickets(); */

    //set state variables for all fields

    const [email, setEmail] = useState('');
    const [permission, setPermission] = useState(false);
    const [edition, setEdition] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [firstname1, setFirstname1] = useState('');
    const [lastname1, setLastname1] = useState('');
    const [email1, setEmail1] = useState('');

    const [phone, setPhone] = useState('');
    const [phone1, setPhone1] = useState('');
    const [agecheck, setAgecheck] = useState(false);
    const [parentsemail, setParentsemail] = useState('');
    const [education, setEducation] = useState('');
    const [othereducation, setOthereducation] = useState('');
    const [schoolname, setSchoolname] = useState('');
    // const [nationality, setNationality] = useState('');
    const [source, setSource] = useState('');
    const [othersource, setOthersource] = useState('');

    const [linkedin, setLinkedin] = useState('');
    //const [femaletshirt, setFemaletshirt] = useState('');
    const [shirtsize, setShirtsize] = useState('');
    const [shirtsize1, setShirtsize1] = useState('');
    const [foodrestriction, setFoodrestriction] = useState('');
    const [foodrestriction1, setFoodrestriction1] = useState('');
    const [otherfoodrestriction, setOtherfoodrestriction] = useState('');
    const [otherfoodrestriction1, setOtherfoodrestriction1] = useState('');
    const [mediaconsent, setMediaconsent] = useState(false);

    //invoice informations
    const [ifirstname, setIfirstname] = useState('');
    const [ilastname, setIlastname] = useState('');
    const [iaddress, setIaddress] = useState('');
    const [icity, setIcity] = useState('');

    const [companyname, setCompanyname] = useState('');
    const [companyaddress, setCompanyaddress] = useState('');
    const [companycity, setCompanycity] = useState('');
    const [companycountry, setCompanycountry] = useState('');
    const [companyregistry, setCompanyregistry] = useState('');
    const [companyvat, setCompanyvat] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        let data = {
            count: 1,
            participant: {
                'email': email,
                "permission": permission,
                "edition": edition,
                "firstname": firstname,
                "lastname": lastname,
                "phone": phone,
                "phone1": phone1,
                "agecheck": agecheck,
                "parentsemail": parentsemail,
                "education": education,
                "othereducation": othereducation,
                "schoolname": schoolname,
                // "nationality": nationality,
                "source": source,
                "othersource": othersource,
                "linkedin": linkedin,
                //"femaletshirt": femaletshirt,
                "shirtsize": shirtsize,
                "shirtsize1": shirtsize1,
                "foodrestriction": foodrestriction,
                "foodrestriction1": foodrestriction1,
                "otherfoodrestriction": otherfoodrestriction,
                "otherfoodrestriction1": otherfoodrestriction1,
                "mediaconsent": mediaconsent,
                "firstname1": firstname1,
                "lastname1": lastname1,
                "email1": email1,
                "ifirstname": ifirstname,
                "ilastname": ilastname,
                'iaddress': iaddress,
                'icity': icity,
                "companyname": companyname,
                "companyaddress": companyaddress,
                "companycity": companycity,
                "companycountry": companycountry,
                "companyregistry": companyregistry,
                "companyvat": companyvat
            },

        }

        //console.log(data)
        history.push({
            pathname: '/overview',
            state: {data} // your data array of objects
        });
    }

    async function checkVies(e) {
        //e.preventDefault();

        let vatnumber = e;
        //console.log(vatnumber);

        //here comes call to checkvies

        async function checkVies(vatnumber) {
            const path = 'checkvies';
            const body = {
                vat: vatnumber,
            }

            return await postData(path, body);
        }

        await checkVies(vatnumber).then(res => {
                let valid = res;
                if (valid === true) {
                    setCompanyvat(vatnumber);
                } else {
                    alert('This VAT number is not valid. Please let the field empty or provide a valid VAT number');
                }
            }
        );

    }

    //console.log({permission});
    //console.log({agecheck});
    //console.log({mediaconsent});


    return (


        <div className={classes.root}>

            <form onSubmit={handleSubmit}>
                <img src={logo} alt="Logo" className={classes.logo}/>

                <Paper elevation={2} className={classes.swgpaperborder}>
                    <Typography variant='h2'>Young Entrepreneurs 2021</Typography>
                    <div className={classes.smalldivider}/>
                    <Typography paragraph>Dear applicant,</Typography>
                    <Typography paragraph>
                        Thank you for your interest in applying to YOUNG ENTREPRENEURS summer school - an intensive
                        7-day program meant to give you a quick taste
                        of the exciting world of entrepreneurship by working closely with renowned industry experts,
                        true entrepreneurs, investors, and
                        practitioners.
                    </Typography>
                    <Typography>WHY?</Typography>
                    <Typography paragraph>
                        Startup Wise Guys, together with Estonian Business School, strongly believe in impacting and
                        inspiring
                        future generations via entrepreneurship!
                    </Typography>
                    <Typography>HOW?</Typography>
                    <Typography paragraph>
                        By creating a dedicated framework where YOU can get acquainted with the exciting world of
                        entrepreneurship.
                    </Typography>
                    <Typography>WHAT?</Typography>
                    <Typography paragraph>
                        A bespoke curriculum, extending over one full week. An all encompassing approach, combining
                        lectures & workshops from top
                        international mentors with real founder stories, company visits, and a hands on hackathon event.
                    </Typography>

                    <Typography paragraph>Price: <br/>
                        Tallinn edition - 300 EUR + VAT <br/>
                        Helsinki edition - 300 EUR + VAT <br/>
                        Friends package 1+1: Tallinn edition - 540 EUR + VAT (2 persons) <br/>
                        Friends package 1+1: Helsinki edition - 540 EUR + VAT (2 persons) <br/>
                    </Typography>
                    <div className={classes.largedivider}/>
                    <Typography paragraph>
                        In case any questions regarding registration and/or organizational issues occur, please contact
                        both:
                    </Typography>
                    <Typography paragraph>
                        Razvan Suta: <a href='mailto:razvan@startupwiseguys.com'>razvan@startupwiseguys.com</a>
                    </Typography>
                    <Typography paragraph>
                        Mirell Merirand: <a href='mailto:mirell.merirand@ebs.ee'>mirell.merirand@ebs.ee</a>
                    </Typography>
                    <Typography paragraph color='primary'>
                        *Required
                    </Typography>
                </Paper>

                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               required
                               fullWidth
                               id="email"
                               label='Email'
                               variant="outlined"
                               value={email}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setEmail(e.target.value)}
                    />
                </Paper>

                <Paper elevation={2} className={classes.swgpaper}>
                    <Typography paragraph>
                        I hereby allow organizers (Startup Wise Guys, Estonian Business School, and potential partners)
                        to collect and process my personal
                        data for the purposes of execution, communication, and follow-up of the Young Entrepreneurs 2021
                        event. All personal data that
                        you provide shall be deleted within 6 months of the event's end date. After entering your
                        personal data into this form, if you
                        wish to revoke Startup Wise Guys & partners' access to your personal data, please contact
                    </Typography>
                    <Typography paragraph>
                        <a href='mailto:gdpr@startupwiseguys.com'>gdpr@startupwiseguys.com</a>
                    </Typography>

                    <Typography paragraph>
                        For more information about how Startup Wise Guys handle sensitive data please refer to our
                        Privacy Statement <a href="https://startupwiseguys.com/privacy-statement/" target="_blank"
                                             rel="noopener noreferrer nofollow">https://startupwiseguys.com/privacy-statement/</a>
                    </Typography>

                    <Typography paragraph>
                        Please also note that you shall receive emails related to this event from Startup Wise Guys to
                        the email address you entered.
                    </Typography>


                    <TextField className={classes.textfield}
                               id="permission"
                               label="I agree"
                               variant="outlined"
                               select
                               required
                               fullWidth
                               value={permission}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setPermission(e.target.value)}
                    >

                        {choices.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </Paper>

                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               id="edition"
                               label="Young Entrepreneurs edition you are signing up for? *"
                               variant="outlined"
                               select
                               fullWidth
                               value={edition}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setEdition(e.target.value)}
                    >

                        {editions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Paper>

                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               required
                               fullWidth
                               id="firstname"
                               label='First name'
                               variant="outlined"
                               value={firstname}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setFirstname(e.target.value)}
                    />
                </Paper>

                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               required
                               fullWidth
                               id="lastname"
                               label='Last name'
                               variant="outlined"
                               value={lastname}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setLastname(e.target.value)}
                    />
                </Paper>

                {(edition === 'EBS Tallinn 1+1 2021' || edition === 'EBS Helsinki 1+1 2021') &&
                <>
                    <Paper elevation={2} className={classes.swgpaper}>
                        <TextField className={classes.textfield}
                                   required
                                   fullWidth
                                   id="firstname1"
                                   label='2. Participant First name'
                                   variant="outlined"
                                   value={firstname1}
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   onChange={e => setFirstname1(e.target.value)}
                        />
                    </Paper>
                    <Paper elevation={2} className={classes.swgpaper}>
                        <TextField className={classes.textfield}
                                   required
                                   fullWidth
                                   id="lastname1"
                                   label='2. Participant Last name'
                                   variant="outlined"
                                   value={lastname1}
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   onChange={e => setLastname1(e.target.value)}
                        />
                    </Paper>

                    <Paper elevation={2} className={classes.swgpaper}>
                        <TextField className={classes.textfield}
                                   required
                                   fullWidth
                                   id="email1"
                                   label='2. Participant Email'
                                   variant="outlined"
                                   value={email1}
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   onChange={e => setEmail1(e.target.value)}
                        />
                    </Paper>
                </>
                }


                <Paper elevation={2} className={classes.swgpaper}>
                    <Typography paragraph>
                        Are you 16 years old or older? *
                    </Typography>

                    <TextField className={classes.textfield}
                               id="agechecked"
                               label="Yes / No"
                               variant="outlined"
                               select
                               required
                               fullWidth
                               value={agecheck}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setAgecheck(e.target.value)}
                    >

                        {choices.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </Paper>

                <Paper elevation={2} className={classes.swgpaper}>
                    <Typography paragraph>
                        If you are under 16 years old, please provide your parent's email address so we can get their
                        consent for collecting/handling/storing data about you upon registration and duration of the
                        summer school.
                    </Typography>

                    <TextField className={classes.textfield}
                               fullWidth
                               id="parentsemail"
                               label="Parent's email"
                               variant="outlined"
                               value={parentsemail}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setParentsemail(e.target.value)}
                    />

                </Paper>

                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               required
                               fullWidth
                               id="phone"
                               label="Phone number (including country prefix (needed for urgent communication purposes during the event)"
                               variant="outlined"
                               value={phone}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setPhone(e.target.value)}
                    />
                </Paper>

                {(edition === 'EBS Tallinn 1+1 2021' || edition === 'EBS Helsinki 1+1 2021') &&
                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               required
                               fullWidth
                               id="phone1"
                               label="2. Participant Phone number (including country prefix (needed for urgent communication purposes during the event)"
                               variant="outlined"
                               value={phone1}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setPhone1(e.target.value)}
                    />
                </Paper>
                }

                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               id="education"
                               label="What is your current educational status? *"
                               variant="outlined"
                               select
                               fullWidth
                               value={education}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setEducation(e.target.value)}
                    >

                        {educations.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div className={classes.smalldivider}/>

                    {education === 'Other' &&
                    <TextField className={classes.textfield}
                               fullWidth
                               id="othereducation"
                               label="Other education"
                               variant="outlined"
                               value={othereducation}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setOthereducation(e.target.value)}
                    />

                    }
                </Paper>

                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               fullWidth
                               id="schoolname"
                               label="Please name the school/university you are currently enrolled in? (if applicable)"
                               variant="outlined"
                               value={schoolname}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setSchoolname(e.target.value)}
                    />
                </Paper>


                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               id="source"
                               label="Where did you find out about Young Entrepreneurs?"
                               variant="outlined"
                               required
                               select
                               fullWidth
                               value={source}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setSource(e.target.value)}
                    >

                        {sources.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div className={classes.smalldivider}/>

                    {source === 'Other' &&
                    <TextField className={classes.textfield}
                               fullWidth
                               id="othersource"
                               label="Other source"
                               variant="outlined"
                               value={othersource}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setOthersource(e.target.value)}
                    />

                    }
                </Paper>


                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               fullWidth
                               id="linkedin"
                               label="Paste here the link to your LinkedIn profile if you have one"
                               variant="outlined"
                               value={linkedin}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setLinkedin(e.target.value)}
                    />
                </Paper>


                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               id="shirtsize"
                               label="Your T-shirt size? (universal fit and tailoring)"
                               variant="outlined"
                               select
                               fullWidth
                               value={shirtsize}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setShirtsize(e.target.value)}
                    >

                        {sizes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Paper>

                {(edition === 'EBS Tallinn 1+1 2021' || edition === 'EBS Helsinki 1+1 2021') &&
                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               id="shirtsize1"
                               label="2. Participant T-shirt size? (universal fit and tailoring)"
                               variant="outlined"
                               select
                               fullWidth
                               value={shirtsize1}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setShirtsize1(e.target.value)}
                    >

                        {sizes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Paper>
                }

                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               id="foodrestriction"
                               label="Do you have any dietary restrictions (we will use this information in order to prepare catering in case of the on-site event)?"
                               variant="outlined"
                               required
                               select
                               fullWidth
                               value={foodrestriction}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setFoodrestriction(e.target.value)}
                    >

                        {foodrestrictions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div className={classes.smalldivider}/>

                    {foodrestriction === 'Other' &&
                    <TextField className={classes.textfield}
                               fullWidth
                               id="otherfoodrestriction"
                               label="Other food restriction"
                               variant="outlined"
                               value={otherfoodrestriction}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setOtherfoodrestriction(e.target.value)}
                    />

                    }
                </Paper>

                {(edition === 'EBS Tallinn 1+1 2021' || edition === 'EBS Helsinki 1+1 2021') &&
                <Paper elevation={2} className={classes.swgpaper}>
                    <TextField className={classes.textfield}
                               id="foodrestriction1"
                               label="2. Participant: Do you have any dietary restrictions (we will use this information in order to prepare catering in case of the on-site event)?"
                               variant="outlined"
                               required
                               select
                               fullWidth
                               value={foodrestriction1}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setFoodrestriction1(e.target.value)}
                    >

                        {foodrestrictions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div className={classes.smalldivider}/>

                    {foodrestriction1 === 'Other' &&
                    <TextField className={classes.textfield}
                               fullWidth
                               id="otherfoodrestriction1"
                               label="2. Participant food restriction"
                               variant="outlined"
                               value={otherfoodrestriction1}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setOtherfoodrestriction1(e.target.value)}
                    />

                    }
                </Paper>
                }

                <Paper elevation={2} className={classes.swgpaper}>
                    <Typography paragraph>
                        Photos and other media materials during the program will be created, collected, and stored by
                        Startup Wise Guys and partners
                        for this event promotion and future promotional purposes. Does Startup Wise Guys and partners
                        have your consent for collecting,
                        storing, and future use of these media materials? *
                    </Typography>

                    <TextField className={classes.textfield}
                               id="mediaconsent"
                               label="Yes / No"
                               variant="outlined"
                               select
                               required
                               fullWidth
                               value={mediaconsent}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setMediaconsent(e.target.value)}
                    >

                        {choices.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </Paper>

                <Paper elevation={2} className={classes.swgpaper}>
                    <Typography paragraph>
                        Following answers are needed to create an invoice for participation. The receiver of the invoice
                        can be you if you are more than 16 years old
                        or your parents if you are less than 16 years old.
                    </Typography>

                    <TextField className={classes.textfield}
                               fullWidth
                               required
                               id="ifirstname"
                               label="Invoice Receiver's First name"
                               variant="outlined"
                               value={ifirstname}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setIfirstname(e.target.value)}
                    />
                    <div className={classes.smalldivider}/>
                    <TextField className={classes.textfield}
                               fullWidth
                               required
                               id="ilastname"
                               label="Invoice Receiver's Last name"
                               variant="outlined"
                               value={ilastname}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setIlastname(e.target.value)}
                    />
                    <div className={classes.smalldivider}/>
                    <TextField className={classes.textfield}
                               fullWidth
                               required
                               id="iaddress"
                               label="Invoice Receiver's Address"
                               variant="outlined"
                               value={iaddress}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setIaddress(e.target.value)}
                    />
                    <div className={classes.smalldivider}/>
                    <TextField className={classes.textfield}
                               fullWidth
                               required
                               id="icity"
                               label="Invoice Receiver's ZIP number & City"
                               variant="outlined"
                               value={icity}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setIcity(e.target.value)}
                    />

                </Paper>

                <Paper elevation={2} className={classes.swgpaper}>
                    <Typography paragraph>
                        If you want to purchase the ticket on a company account, please provide the following data for
                        the invoice:
                    </Typography>

                    <TextField className={classes.textfield}
                               fullWidth
                               id="companyname"
                               label="Company name"
                               variant="outlined"
                               value={companyname}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setCompanyname(e.target.value)}
                    />
                    <div className={classes.smalldivider}/>
                    <TextField className={classes.textfield}
                               fullWidth
                               id="companyaddress"
                               label="Company address"
                               variant="outlined"
                               value={companyaddress}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setCompanyaddress(e.target.value)}
                    />
                    <div className={classes.smalldivider}/>
                    <TextField className={classes.textfield}
                               fullWidth
                               id="companycity"
                               label="Company ZIP number and City"
                               variant="outlined"
                               value={companycity}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setCompanycity(e.target.value)}
                    />
                    <div className={classes.smalldivider}/>

                    <TextField className={classes.textfield}
                               id="companycountry"
                               label="In which country is the company registered"
                               variant="outlined"
                               select
                               fullWidth
                               value={companycountry}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setCompanycountry(e.target.value)}
                    >

                        {countries.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <div className={classes.smalldivider}/>
                    <TextField className={classes.textfield}
                               fullWidth
                               id="companyregistry"
                               label="Company registry number"
                               variant="outlined"
                               value={companyregistry}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => setCompanyregistry(e.target.value)}
                    />
                    <div className={classes.smalldivider}/>
                    <TextField className={classes.textfield}
                               fullWidth
                               id="companyvat"
                               label="Company VAT number"
                               variant="outlined"
                               value={companyvat}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               onChange={e => checkVies(e.target.value)}
                    />

                </Paper>


                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                >
                    Submit
                </Button>


            </form>

            <div className={classes.largedivider}/>
            <Grid
                justify="space-between" // Add it here :)
                container
                spacing={3}
                className={classes.footer}
            >
                <Grid item>
                    <Typography variant="h6" className={classes.title}>
                        Copyright © {new Date().getFullYear()} by StartupWiseGuys
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h6" className={classes.title}>
                        <Link href="https://startupwiseguys.com/privacy-statement/" target="_blank" rel="noopener"
                              className={classes.link}>
                            Privace Notice
                        </Link>
                    </Typography>
                </Grid>
            </Grid>


        </div>

    );
}

/*

<TextField className={classes.textfield}
    required
    fullWidth
    id="pemail10"
    label='Participant Email'
    variant="outlined"
    value={pemail10}
    InputLabelProps={{
        shrink: true,
    }}
    onChange={e => setPemail10(e.target.value)}
/>

<TextField className={classes.textfield}
    id="country"
    label="Country"
    variant="outlined"
    select
    fullWidth
    value={country}
    InputLabelProps={{
        shrink: true,
    }}
    onChange={e => setCountry(e.target.value)}
>

    {countries.map((option) => (
        <MenuItem key={option.value} value={option.value}>
            {option.label}
        </MenuItem>
    ))}
</TextField>
*/

export default Home1;
