import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F7F8F9',
    },
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    title: {
        flexGrow: 1,
        fontSize: '1.2em',
    },
    link: {
        color: "#f1f1f1"
    }


}));

export default function Footer() {
    const classes = useStyles();

    //const year = '2021';

    return (
        <div className={classes.root}>
            <AppBar color="secondary" className={classes.appBar}>
                <Toolbar>
                    <Grid
                        justify="space-between" // Add it here :)
                        container
                        spacing={3}
                    >
                        <Grid item>
                            <Typography variant="h6" className={classes.title}>
                                Copyright © {new Date().getFullYear()} by StartupWiseGuys
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className={classes.title}>
                                <Link href="https://startupwiseguys.com/privacy-statement/" target="_blank" rel="noopener" className={classes.link}>
                                    Privace Notice
                                </Link>


                            </Typography>
                        </Grid>
                    </Grid>

                </Toolbar>
            </AppBar>
        </div>
    );

}